<template>
  <div class="notice">
    <div class="notice_body">
      <div>
        <div class="warning">
          您需要配送的自提点有{{ total_sites }}个，已按照系统设置为您划分为{{
            list.length
          }}个区域
        </div>
        <template v-if="list.length > 0">
          <template v-for="(group, groupIndex) in list">
            <div class="area-name">{{ group.group_name }}</div>
            <template v-for="(item, index) in group.sites">
              <div class="card">
                <div class="card-title" @click="showQrcode(item)">
                  <div class="site-name">{{ item.site_name }}</div>
                  <div class="number">{{ item.goods_number }}个商品</div>
                </div>
                <div class="card-body">
                  <div class="goods-list">
                    <div
                      class="goods-info"
                      v-for="(goods, goodsIndex) in item.goods"
                    >
                      <div class="goods-img">
                        <img :src="goods.path" />
                      </div>
                      <div class="info">
                        <div class="goods-title">
                          {{ goods.title }}
                        </div>
                        <div class="goods-specs">
                          {{ goods.spec_text ? goods.spec_text : "默认规格" }}
                        </div>
                      </div>
                      <div class="goods-number">× {{ goods.total }}</div>
                    </div>
                  </div>

                  <template v-if="item.after_sale_goods.length > 0">
                    <div class="after-sales-goods-title">售后配送商品列表:</div>
                    <div class="goods-list">
                      <div
                        class="goods-info"
                        v-for="(goods, goodsIndex) in item.after_sale_goods"
                      >
                        <div class="goods-img">
                          <img :src="goods.path" />
                        </div>
                        <div class="info">
                          <div class="goods-title">
                            {{ goods.title }}
                          </div>
                          <div class="goods-specs">
                            {{ goods.spec_text ? goods.spec_text : "默认规格" }}
                          </div>
                        </div>
                        <div class="goods-number">× {{ goods.total }}</div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </template>
        </template>
      </div>
    </div>

    <nut-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '90%' }"
      :close-on-click-overlay="false"
    >
      <div class="confirm-window">
        <div class="title">
          配送详情

          <div class="close-area" @click="hideWindow">
            <img
              class="close-button"
              src="https://api.mengjingloulan.com/storage/v3/close-bold.png"
            />
          </div>
        </div>
        <div class="content">
          <div class="site-info" @click="contact(record.mobile)">
            <div class="site-name">{{ record.site_name }}</div>
            <div class="site-leader">
              负责人：{{ record.site_leader }} 手机号码：{{ record.mobile }}
            </div>
          </div>
          <div class="qrcode">
            <img :src="qrcode" />
            {{ showOrderSn }}
          </div>
          <div class="warning">
            在您与自提点完成货物交接后，请务必将此码提供给自提点进行扫描，自提点扫描此送货码并确认送货成功后才会更新您配送订单的状态
          </div>

          <div class="buttons">
            <button class="btn" @click="confirmSendGoods">
              确认送到自提点
            </button>
          </div>
        </div>
      </div>
    </nut-popup>
  </div>
</template>

<script>
import searchV1 from "../../template/community/search/searchV1";
import CellV3 from "../../template/community/cell/cellV3";

export default {
  name: "send-notice",
  components: { CellV3, searchV1 },
  data() {
    return {
      keyword: "",
      page: 1,
      params: {
        page: 1,
        page_size: 10,
      },
      list: [],
      scrollHeight: 0,
      show: false,
      record: {},
      qrcode: "",
      showOrderSn: "",
      total_sites: 0,
    };
  },
  mounted() {
    this.showResult = true;
    this.getNoticeList();
    this.scrollHeight = window.innerHeight;
    document.title = "配送中自提点列表";
  },
  methods: {
    async getNoticeList() {
      try {
        let query = await this.$api.community.courier.getSendList();
        if (query.data.length == 0) {
          return;
        }
        this.total_sites = query.data.total_sites;
        this.list = query.data.lists;
      } catch (e) {
        // console.log("通知列表获取失败");
      }
    },
    showQrcode(record) {
      this.record = record;
      let params = {
        site_id: record.site_id,
        goods: record.goods.map((item) => {
          return {
            goods_id: item.goods_id,
            spec_ids: item.spec_ids,
            spec_text: item.spec_text,
            total: item.total,
            title: item.title,
            path: item.path,
          };
        }),
      };
      this.$api.community.courier
        .getSendReceiveCode(params)
        .then((response) => {
          this.qrcode = response.data.url + "?rand=" + Math.random();
          this.showOrderSn = response.data.sn;
        });
      this.show = true;
    },
    hideWindow() {
      this.show = false;
      this.qrcode = "";
      // this.showOrderSn = "";
    },
    confirmSendGoods() {
      let self = this;
      let params = {
        site_id: this.record.site_id,
        order_sn: this.showOrderSn,
        goods: this.record.goods.map((item) => {
          return {
            goods_id: item.goods_id,
            spec_ids: item.spec_ids,
            spec_text: item.spec_text,
            total: item.total,
            title: item.title,
            path: item.path,
          };
        }),
        afterSaleGoods: this.record.after_sale_goods.map((item) => {
          return {
            goods_id: item.goods_id,
            spec_ids: item.spec_ids,
            spec_text: item.spec_text,
            total: item.total,
            titme: item.title,
            path: item.path,
          };
        }),
      };
      this.$api.community.courier
        .confirmSendToSitePoints(params)
        .then((response) => {
          this.$dialog({
            title: "温馨提示",
            content: "确认送到自提点状态更新成功",
            closeBtn: true, //显式右上角关闭按钮
            noCancelBtn: true,
            onOkBtn() {
              self.$dialog.closed();
              self.hideWindow();
              self.getNoticeList();
            },
          });
        })
        .catch((error) => {
          this.$toast.fail(error.message);
        });
    },
    contact(mobile) {
      self.$platform.wxsdk.wxRoute({
        type: "navigateTo",
        url: "/web/makePhoneCall/common?mobile=" + mobile,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@include b(notice) {
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include e(body) {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 15px 0;
    overflow-y: scroll;
  }
}

.card {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  width: 345px;
  margin: 0 auto;
  margin-bottom: 15px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

  .card-title {
    flex: 1;
    display: flex;
    align-items: center;
    height: 48px;
    background: linear-gradient(129deg, #fbf2e2 0%, #f1e2c9 100%);
    border-radius: 10px 10px 0px 0px;
    padding: 0 10px;
    color: #6c4806;

    .site-name {
      flex: 1;
      font-size: 16px;
    }

    .number {
      width: 80px;
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
    }
  }

  .card-body {
    width: 100%;
    background: #fff;
    border-radius: 0px 0px 10px 10px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 10px;

    .goods-list {
      flex: 1;
      display: flex;
      flex-direction: column;

      .goods-info {
        flex: 1;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;

        .goods-img {
          width: 50px;
          height: 50px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 50px;
            height: 50px;
          }
        }

        .info {
          flex: 1;
          display: flex;
          flex-direction: column;

          .goods-title {
            font-size: 16px;
          }

          .goods-specs {
            font-size: 14px;
            color: #ccc;
            margin-top: 6px;
          }
        }

        .goods-number {
          width: 80px;
          display: flex;
          align-items: baseline;
          justify-content: flex-end;
          font-size: 16px;
          color: red;
        }
      }
    }
  }
}

.confirm-window {
  display: flex;
  flex-direction: column;

  .title {
    flex: 1;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.6);
    padding: 15px 0;
    position: relative;

    .close-area {
      width: 50px;
      height: 50px;
      position: absolute;
      right: 10px;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .close-button {
        width: 15px;
        height: 15px;
      }
    }
  }

  .content {
    font-size: 16px;
    color: #333;
    padding: 10px 20px;
    line-height: 25px;
    display: flex;
    flex-direction: column;

    .site-info {
      flex: 1;
      border: #ccc dashed 1px;
      padding: 5px 10px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      background: #fff9ec;
      color: #333;

      .site-name {
        font-size: 16px;
      }

      .site-leader {
        font-size: 14px;
        color: #999;
      }
    }

    .qrcode {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      img {
        width: 80%;
      }
    }

    .warning {
      padding: 20px 20px 10px 20px;
      text-indent: 20px;
      font-size: 14px;
      color: #999;
    }
  }
}

.buttons {
  background: #fff;
  height: 50px;
  padding: 10px 50px;
  display: flex;

  .btn {
    flex: 1;
    bottom: calc(30px + env(safe-area-inset-bottom));
    bottom: calc(30px + constant(safe-area-inset-bottom));
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
    background-clip: padding-box;
    border: 4px solid rgba(244, 106, 23, 0.15);
    border-radius: 29px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}

.warning {
  font-size: 14px;
  padding: 20px 50px;
  background: #fff;
  margin: 0 15px 15px 15px;
  border-radius: 5px;
  line-height: 30px;
}

.area-name {
  font-size: 16px;
  color: #333;
  padding: 10px 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.after-sales-goods-title {
  font-size: 14px;
  color: #fff;
  margin: 10px 0 20px 0;
  background: #108ee9;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
</style>
